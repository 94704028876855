export default defineNuxtRouteMiddleware((to, from) => {
  //restoreState: true,
  //可持久化
  const isLogin = useisLogin()
  const isAuthenticated = () => {
    return isLogin.value
  }
  // isAuthenticated()是一个验证用户是否已经认证的示例方法
  if (isAuthenticated() == true) {
    return '/home'
    // return navigateTo({name:'home'})
  }
})
